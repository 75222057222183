// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-drawings-js": () => import("./../../../src/pages/drawings.js" /* webpackChunkName: "component---src-pages-drawings-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-objects-js": () => import("./../../../src/pages/objects.js" /* webpackChunkName: "component---src-pages-objects-js" */),
  "component---src-pages-prints-js": () => import("./../../../src/pages/prints.js" /* webpackChunkName: "component---src-pages-prints-js" */),
  "component---src-templates-gallery-detail-js": () => import("./../../../src/templates/galleryDetail.js" /* webpackChunkName: "component---src-templates-gallery-detail-js" */)
}

